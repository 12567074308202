import React, {useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "./Compare.css";
import HomeLogo from "../../components/HomeLogo";
import ProfileIcon from "../../components/ProfileIcon";
import {useHistory, useLocation} from 'react-router-dom';
import FilmBox from "../../components/FilmBox";
import RoundedButton from "../../components/RoundedButton";
import createPlotlyComponent from 'react-plotly.js/factory';
import table1 from "./table1";
import table_compare from "./table_compare";
import { BASE_URL } from "../../constants/company-info";
import { consoleLog, consoleError } from '../../helpers/loggers';

function Compare() {
    const location = useLocation();
    const athleteId = location.state?.athleteId;
    const isCoach_str = localStorage.getItem('is_coach');

    if (!athleteId) {
      if (isCoach_str === 'false') {
        return <Redirect to='/my-film' />
      }
      else {
        return <Redirect to='/manage-teams' />
      }
    };
    
    const athleteName = location.state?.athleteName;
    const history = useHistory();
    const userId = location.state?.userId;
    const name = location.state?.name;
    const currVideo = location.state?.currVideo;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMyFilm, setSelectedMyFilm] = useState(null);
    const [selectedOtherFilm, setSelectedOtherFilm] = useState(null);
    const [video1, setVideo1] = useState(currVideo);
    const [video2, setVideo2] = useState(null);

    const [films, setFilms] = useState([]);
    const [videoIndex, setVideoIndex] = useState(0);
    const [otherFilms, setOtherFilms] = useState([]);
    const [comparisonFilm, setComparisonFilm] = useState();
    const [comp, setComp] = useState(false)

  
  useEffect (() => {
    // Get video for athlete
    fetch(BASE_URL + '/api/get_film?athleteUserId='+athleteId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setFilms(data);
          setComparisonFilm(data[0][0]);
        } else {
          // Handle the case where data is not an array
          consoleError('Received data is not an array:', data);
          setFilms([]); // Reset to empty array or handle accordingly
        }
      })
      .catch(error => {
          consoleError('Error getting athlete videos: ', error);
      });

    // Get videos from other athletes
    fetch(BASE_URL + '/api/get_other_film?athleteUserId='+athleteId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setOtherFilms(data);
        } else {
          consoleError('Received data is not an array:', data);
          setOtherFilms([]);
        }
      })
      .catch(error => {
          consoleError('Error getting other videos: ', error);
      });
  }, []);

  const handleBack = () => {

    history.push({
      pathname: '/analyze', // The target route
      state: {
        athleteName: athleteName,
        athleteId: athleteId,
        userId: userId,
        coachName: name,

      }
    });
  };
  const handleVideoBoxClick = () => {
      setIsModalOpen(true);
  }

  const handleClear = () =>{
      setIsModalOpen(true);
  }


  const handleForwardComp = () => {
      // setVideo1(selectedMyFilm);
      // setVideo2(selectedOtherFilm);
      setIsModalOpen(false);
      setVideo2(comparisonFilm);
  }

  const handleMyFilmSelection = (film) => {
    if (film !== selectedMyFilm) {
        setSelectedOtherFilm(null);
        setSelectedMyFilm(film);
    } else {
        setSelectedMyFilm(null);
    }
    setComparisonFilm(film);
    setSpeed(speed2);
    setDistance(distance2);
    setComp(true);
  };

  const handleOtherFilmSelection = (film) => {
    if (film !== selectedOtherFilm) {
        setSelectedMyFilm(null);
        setSelectedOtherFilm(film);
    } else {
        setSelectedOtherFilm(null);
    }
    setComparisonFilm(film);
    setSpeed(speed2);
    setDistance(distance2);
    setComp(true);
  };

  const Plotly = require('plotly.js-basic-dist');
  const Plot = createPlotlyComponent(Plotly);

  const [speed, setSpeed] = useState([4.5,4.6,4.6,4.4,4.8,5.0,4.8,4.8,4.7,4.8,4.8]);
  const [distance, setDistance] = useState([5,10,15,25,30,35,40,45,50,55,60]);
  const speedDistanceData = [{
    x: distance,
    y: speed,
    mode:"markers",
    type:"scatter",
    marker: {
      color: speed.map(speedValue => speedValue > 4 ? 'white' : 'yellow')
    }
  }];

  const speed2 = [3.6,3.7,3.5,3.6,3.2,3.6,3.5,3.1,3.0,3.0,3.2,4.5,4.6,4.6,4.4,4.8,5.0,4.8,4.8,4.7,4.8,4.8];
  const distance2 = [5,10,15,25,30,35,40,45,50,55,60,5,10,15,25,30,35,40,45,50,55,60];

  const speedDistanceLayout = {
    xaxis: {range: [0,65], title: "Distance (m)", color: '#ffffff', showgrid: false},
    yaxis: {range: [2.9, 5.2], title: "Speed (mph)", color: '#ffffff', showgrid: false},
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    shapes: [
      {
        type: 'line',
        x0: 0,
        y0: 2.9,
        x1: 65,
        y1: 2.9,
        line: {
          color: 'white',
          width: 2
        }
      }
    ],
    margin: { t: 30 }
  };
  

  return (
    <div className="compare-page">
      <div className= "compare-content-container">
        <div className = "videos">
            <div className="video1-box">
                <iframe
                  title="Swimmer Video"
                  width="100%"
                  height="100%"
                  src={video1}
                  allowFullScreen
                ></iframe>

            </div>
            <div className="video2-box" onClick={handleVideoBoxClick}>
                {video2 && (
                  <iframe
                    title="Swimmer Video"
                    width="100%"
                    height="100%"
                    src={video2}
                    allowFullScreen
                  ></iframe>
                )}

                {!video2 && (
                  "Click to Select Video"
                )}
            </div>


            <button className="clear-button" onClick={() => handleClear()}> Clear </button>




        </div>

        {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
               <div className="my-films">
                  <div className="my-film-label">My Film</div>
                  <div className="my-film-boxes">
                    {films.map((film, index) => (
                      <FilmBox 
                        key={film[0]}
                        url={film[0]}
                        thumbnail={film[1]}
                        selected={film[0] == comparisonFilm || film[0] == video1}
                        // favorited={favorited.includes(films[index][2])}
                        onClick={() => handleMyFilmSelection(film[0])}
                      />
                    ))}
                    {/* <div className={`my-film-box ${selectedMyFilm === 'Film1' ? 'selected' : ''}`} onClick={() => handleMyFilmSelection('Film1')}>Film 1</div>
                    <div className={`my-film-box ${selectedMyFilm === 'Film2' ? 'selected' : ''}`} onClick={() => handleMyFilmSelection('Film2')}>Film 2</div>
                    <div className={`my-film-box ${selectedMyFilm === 'Film3' ? 'selected' : ''}`} onClick={() => handleMyFilmSelection('Film3')}>Film 3</div> */}
                  </div>
               </div>

                <div className="other-film">
                  <div className="other-film-label">Other Film</div>
                  <div className="my-film-boxes">
                    {otherFilms.map((film, index) => (
                      <FilmBox 
                        key={film[0]}
                        url={film[0]}
                        thumbnail={film[1]}
                        selected={film[0]==comparisonFilm}
                        // favorited={favorited.includes(films[index][2])}
                        onClick={() => handleOtherFilmSelection(film[0])}
                      />
                    ))}
                    {/* <div className={`my-film-box ${selectedOtherFilm === 'Film1' ? 'selected' : ''}`} onClick={() => handleOtherFilmSelection('Film1')}>Film 1</div>
                    <div className={`my-film-box ${selectedOtherFilm === 'Film2' ? 'selected' : ''}`} onClick={() => handleOtherFilmSelection('Film2')}>Film 2</div>
                    <div className={`my-film-box ${selectedOtherFilm === 'Film3' ? 'selected' : ''}`} onClick={() => handleOtherFilmSelection('Film3')}>Film 3</div> */}
                  </div>
                </div>

            {/* <div className="search-fields">
                Future Search Fields
            </div> */}
            <div className="back-button">
                <RoundedButton label='❌' onClick={() => setIsModalOpen(false)} />
            </div>
            <div className="forward-button">
                <RoundedButton label='→' onClick={() => handleForwardComp()} />
            </div>





          </div>
        </div>
        )}

        <div className="stats">

            <RoundedButton label='←' onClick={() => handleBack()} />

            <div className="swimmer-stat_stats">
              <div className="swimmer-data-title">Swimmer Stats</div>
              {comp ? table_compare() : table1()}
            </div>

            <div className="swimmer-data_stats">
              <div className="swimmer-data-title">Swimmer Data</div>
              <Plot
                  data={speedDistanceData}
                  layout={speedDistanceLayout}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                />
            </div>

            <RoundedButton label='💾' onClick={() => consoleLog("Save")} />


        </div>
      </div>
    </div>
  );
}
export default Compare;
