import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./SignUp.css";
import HomeLogo from "../../components/HomeLogo";
import LogInTemplate from "../../components/LogInTemplate";
import AuthContext from "../../context/AuthContext";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye';
import { BASE_URL } from "../../constants/company-info";
import { consoleLog, consoleError } from '../../helpers/loggers';

// helper function to extract cookie
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

// Retrieve CSRF Token
async function getCsrfToken() {
  try {
      const dummyResponse = await fetch(BASE_URL + '/api/csrf_cookie', {
          credentials: 'include',
      });
      if (!dummyResponse.ok) {
          consoleError('HTTP Error setting CSRF cookie');
          return null;
      }
      const csrfToken = getCookie('csrftoken');
      // consoleLog('CSRF Token:', csrfToken);
      return csrfToken;
  } catch (error) {
      consoleError('There was a problem with your fetch csrf_cookie operation:', error);
      return null;
  }
}

function SignUp() {
  const history = useHistory();
  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');

  const [createUserError, setCreateUserError] = useState('');

  const [passwordType, setPasswordType] = useState('password');
  const [passwordConfType, setPasswordConfType] = useState('password');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    const isCoach = localStorage.getItem('is_coach') === 'true';

    if (isCoach) {
      history.push('/manage-teams');
    } else if (localStorage.getItem('is_coach') === 'false') {
      history.push('/my-film');
    } 
  }, [history]);

  const handleSignup = async () => {
    
    // GET request to retrieve token
    const csrfToken = await getCsrfToken() || 'N/A';
    if (!csrfToken) {
        consoleError('Failed to retrieve CSRF token.');
        return;
    }

    // compile User data
    const data = {
      "email": email,
      "username": username,
      "password": password,
      "password_conf": passwordConf
    };

    // POST request to register user
    if (csrfToken) {
      fetch(BASE_URL + '/api/register', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            setCreateUserError(data.error);
          }
          else {
            setCreateUserError('');
            login(data.token);
            localStorage.setItem('is_coach', 'TBD');
            localStorage.setItem('userId', data["user_id"])

            const updatePhone = {
                "new_phone_number": phonenumber,
                "user_id": data["user_id"]
              };

              fetch(`${BASE_URL}/api/update_phone/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatePhone),
              })

            // route on successful registration
            history.push({
              pathname: '/get-started',
              state: { userId: data["user_id"] }
            });
          }
        })
        .catch(error => {
            alert(error)
            consoleError('Error registering user: ', error);
        });
    };
  }

  const allFieldsFilled = email && phonenumber && username && password && passwordConf;

  const formContent = (
      <div className="form">
          <label>Enter your email address</label>
          <input
              type="email"
              className="form-control mt-1"
              placeholder="Email address"
              value={email}
              onChange={(e) => {
                  setEmail(e.target.value);
                  setCreateUserError('');
              }}
          />
          <label>Enter a phone number</label>
          <input
              type="tel"
              className="form-control mt-1"
              placeholder="Phone number"
              value={phonenumber}
              onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setCreateUserError('');
              }}
          />
          <label>Enter a username</label>
          <input
              type="username"
              className="form-control mt-1"
              placeholder="Username"
              value={username}
              onChange={(e) => {
                  setUsername(e.target.value);
                  setCreateUserError('');
              }}
          />

          <label>Enter your password</label>
          <div className="input-group">
              <input
                  type={passwordVisible ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
              />
              <span className="input-group-icon" onClick={() => setPasswordVisible(!passwordVisible)}>
            <Icon icon={passwordVisible ? eye : eyeOff} size={20}/>
          </span>
          </div>

          <label>Confirm your password</label>
          <div className="input-group">
              <input
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Confirm Password"
                  value={passwordConf}
                  onChange={(e) => setPasswordConf(e.target.value)}
              />
              <span className="input-group-icon" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
            <Icon icon={confirmPasswordVisible ? eye : eyeOff} size={20}/>
          </span>
          </div>

          {createUserError && <div className="error-message">{createUserError}</div>}
          <button
              className="submit-log-in"
              disabled={!allFieldsFilled}
              onClick={handleSignup}
          >
              Sign up
          </button>
      </div>
  );

    return (
        <div className="signup-page">
            <div className='background'></div>
            <HomeLogo/>
            <LogInTemplate
                needOrHaveAnAccount={"Already have an account?"}
                signUpOrLogIn={<Link to="/log-in">Log in</Link>}
                title = {"Sign Up"}
            form = {formContent}
        />
    </div>
  );
}

export default SignUp;
