import React, { useState } from 'react';
import './ContactForm.css';
import emailjs from '@emailjs/browser';
import { consoleLog } from '../../helpers/loggers';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [interest, setInterest] = useState('');
  const [user, setUser] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // Success message state

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    // Clear any previous error messages
    setError('');
    setSuccessMessage('');
    // Custom validation for the form fields
    if (!name || !email || !message || !user || !interest) {
      setError('All fields are required.');
      return;
    }

    if (!email.includes('@')) {
      setError('Please enter a valid email address.');
      return;
    }

    // Your EmailJS service ID, template ID, and public key
    const serviceId = 'service_y8hkqt8';  // Replace with your actual service ID
    const templateId = 'template_21043ox'; // Replace with your actual template ID
    const publicKey = 'Iu1SJJumwqDa_76Pe'; // Replace with your actual public key

    // Create an object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Avery',  // This can be dynamic if you want
      message: message,
      interest: interest,
      user: user,
    };

    // Send the email using EmailJS with proper formatting
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        consoleLog('Email sent successfully!', response);

        // Display success message and clear form fields
        setSuccessMessage('Message Sent!');
        setName('');
        setEmail('');
        setMessage('');
        setInterest('');
        setUser('');
      })
      .catch((error) => {
        consoleLog('Failed to send email:', error);
        setError('Failed to send the message. Please try again.');
      });
  };

  const handleInterestClick = (selectedInterest) => {
    setError('');
    setInterest(selectedInterest);
  };

  const handleUserClick = (selectedUser) => {
    setError('');
    setUser(selectedUser);
  };

  return (
    <div className="contact-form">
      I'm interested in...
      <div className="interests-box">
        <button
          className={`product-interests ${interest === 'Swimbot' ? 'active' : ''}`}
          type="button"
          onClick={() => handleInterestClick('Swimbot')}
        >
          Swimbot
        </button>
        <button
          className={`product-interests ${interest === 'Other' ? 'active' : ''}`}
          type="button"
          onClick={() => handleInterestClick('Other')}
        >
          Other
        </button>
      </div>
      I am a...
      <div className="roles-box">
        <button
          className={`role-button ${user === 'Coach' ? 'active' : ''}`}
          type="button"
          onClick={() => handleUserClick('Coach')}
        >
          Coach
        </button>
        <button
          className={`role-button ${user === 'Athlete' ? 'active' : ''}`}
          type="button"
          onClick={() => handleUserClick('Athlete')}
        >
          Athlete
        </button>
        <button
          className={`role-button ${user === 'Other' ? 'active' : ''}`}
          type="button"
          onClick={() => handleUserClick('Other')}
        >
          Other
        </button>
      </div>


      {/* EmailJS Form */}
      <form onSubmit={handleSubmit} noValidate>
        <input
          type="text"
          name="user_name"
          className="form-control mt-1 contact-form-input"
          placeholder="Your name"
          value={name}
          onChange={(e) => {setName(e.target.value); setError('');}}
          required
        />
        <input
          type="email"
          name="user_email"
          className="form-control mt-1 contact-form-input"
          placeholder="Your email"
          value={email}
          onChange={(e) => {setEmail(e.target.value); setError('');}}
          required
        />
        <textarea
          name="message"
          className="message-area contact-form-input"
          placeholder="Your message"
          value={message}
          onChange={(e) => {setMessage(e.target.value); setError('');}}
          required
        />

        {error && <div className="error-message">{error}</div>}

      <div className="button-container">
          <button type="submit" className="send-message-button">
              Send
          </button>

          {/* Success message */}
          {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
      </form>
    </div>
  );
}

export default ContactForm;