import React, { useEffect, useState } from "react";
import "./MyFilm.css";
import HomeLogo from "../../components/HomeLogo";
import ProfileIcon from "../../components/ProfileIcon";
import TeamBox from "../../components/TeamBox";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import FilmBox from "../../components/FilmBox";
import { BASE_URL } from "../../constants/company-info";
import { consoleError } from "../../helpers/loggers";

function MyFilm() {
  const location = useLocation();
  const history = useHistory();
  const userId = location.state?.userId || localStorage.getItem('userId');

  const isCoach_str = localStorage.getItem('is_coach');

  if (isCoach_str === 'true') {
    return <Redirect to='/manage-teams' />
  };

  const [teams,setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [athleteId, setAthleteId] = useState("");
  const [athleteName, setAthleteName] = useState("");

  const [favorited, setFavorited] = useState([]); // list of video ids
  const [films, setFilms] = useState([]);


  // Set selected team
  const handleTeamSelect = (teamId) => {
    setSelectedTeam(teamId);
  };

  // Route to a video on the analyze page
  const handleSelectFilm = (videoId, videoIndex) => {

    history.push({
      pathname: '/analyze', // The target route
      state: {
        athleteName: athleteName,
        athleteId: userId,
        userId: userId,
        videoId: videoId,
        videoIndex: videoIndex
      }
    });
  };

  // Get teams for athlete
  useEffect (() => {
    if (userId) {
      fetch(BASE_URL + '/api/get_teams?user_id='+userId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            setTeams(data);
            if (data.length > 0) {
              setSelectedTeam(data[0].id);
            }
          } else {
            consoleError('Received data is not an array:', data);
            setTeams([]);
          }
        })
        .catch(error => {
            consoleError('Error getting teams: ', error);
        });

      // Get athlete Id
      fetch(BASE_URL + '/api/get_athleteid?user_id='+userId, {
          method: "GET",
          credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
          setAthleteId(data[0])
          setAthleteName(data[1])
          // Get all films for athlete
          fetch(BASE_URL + '/api/get_film?athleteUserId='+userId, {
            method: "GET",
            credentials: 'include'
          })
          .then(response => response.json())
          .then(data => {
            if (data.error) {
              setFilms([]);
            } else {
              setFilms(data);
            }
          })
          .catch(error => {
              consoleError('Error getting athlete videos: ', error);
          });
        })
        .catch(error => {
            consoleError('Error getting athlete id: ', error);
        });

        // Retrieve favorited videos for this coach and athlete
        fetch(BASE_URL + '/api/favorite?userId='+userId, {
          method: "GET",
          credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
          setFavorited(data);
        })
        .catch(error => {
            consoleError('Error getting favorited videos: ', error);
        });
    }
  }, []);

  return (
    <div className="my-film-page">
      <div className="header">
        <HomeLogo/>
        <ProfileIcon userId={userId}/>
      </div>
      <div className="welcome-athlete">
          Welcome, {athleteName}
      </div>

      <div className="my-film-header">
        <div className="my-film-title" >My Film</div>
        <div className="my-film-desc" >Select a team to view your films.</div>
        <div>
          {teams.map((team) => (
              <TeamBox
                  key={team.id}
                  name={team.name}
                  onClick={() => handleTeamSelect(team.id)}
                  selected={team.id === selectedTeam}
              />
          ))}
        </div>
      </div>

      <div className="athletes">
        <div className="my-film-desc" style={{"marginTop": "-15px", "marginBottom": "15px"}}>Films</div>
        <div className="films-grid">
        {films.length === 0 ? (
          <p className="no-films-msg">No films found.</p>
        ) : (
          films.map((film, index) => (
            <FilmBox 
              key={film[0]}
              url={film[0]}
              thumbnail={film[1]}
              favorited={favorited.includes(films[index][2])}
              onClick={() => handleSelectFilm(film[2], index)}
            />
          ))
        )}
        </div>
      </div>

    </div>
  );
}

export default MyFilm;
