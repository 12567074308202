import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { BASE_URL } from '../../constants/company-info';
import './ProfileIcon.css';
import AuthContext from '../../context/AuthContext';
import Select from "react-select";
import { consoleLog, consoleError } from '../../helpers/loggers';

function ProfileIcon({userId, flip_on_change=false, onLogout=null}) {
    const [isprofileDropdownOpen, setIsprofileDropdownOpen] = useState(false);
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState([{ id: -1, message: 'No notifications', onClick: () => {} }])
    //consoleLog("REAL", notifications);
    const [sentFriendRequests, setSentFriendRequests] = useState([]);
    const [receivedFriendRequests, setReceievedFriendRequests] = useState([]);
    //consoleLog(receivedFriendRequests);
    const [coachId, setCoachId] = useState(-1);
    const [coachName, setCoachName] = useState("");
    const history = useHistory();
    const profileDropdownRef = useRef(null);
    const notificationDropdownRef = useRef(null);
    const location = useLocation();
    const [isCoach, setIsCoach] = useState(false);
    const name = location.state?.name;
    const address = location.state?.address;
    const { logout } = useContext(AuthContext);
    consoleLog("UserID", userId);


    const [isFriendDropdownOpen, setIsFriendDropdownOpen] = useState(false);
    const friendDropdownRef = useRef(null);
    const [requestSent, setIsRequestSent] = useState(false);

    const [newFriend, setNewFriend] = useState(null);
    const [allUsers, setAllUsers] = useState([]);

    const handleLogout = () => {
        logout();
        history.push('/'); // Redirect to login page after logout
    };

    const setCoachDetails = (id, name) => {
        setCoachName(name);
        setCoachId(id);
        setIsCoach(true);
    };
    useEffect(() => {
        //consoleLog("testing");
        fetch(BASE_URL + '/api/get_users/', {
            method: "GET",
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                // Transform the user data to match the required format for react-select
                const usersArray = Object.entries(data).map(([id, details]) => ({
                    value: id,  // User ID as the value
                    label: `${details.name} (${details.username})  ${details.email}`  // Name and username as the label
                }));
                setAllUsers(usersArray); // Store the transformed users in state
            } else {
                consoleError('No users found');
            }
        })
        .catch(error => {
            consoleError('Error fetching user details:', error);
        });
    }, []);




    useEffect(() => {
        if (userId) {
            fetch(BASE_URL + '/api/get_coachid?user_id='+userId, {
                method: "GET",
                credentials: 'include'
            })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    return;
                }
                setCoachDetails(data[0], data[1]);
            })
            .catch(error => {
                setIsCoach(false);
                consoleError('Not a coach: ', error);
            });
        } else {
            setIsCoach(false);
        }
    }, [userId, coachId, coachName]);

      const handleAssignFilm = (videoId, videoIndex, athleteName, athleteId) => {


        history.push({
            pathname: '/all-films', // The target route
            state: {
                userId: userId,
                coachId: coachId,
                coachName: coachName,
            }
          });
      };



    useEffect(() => {
        if (userId) {
            fetch(BASE_URL + '/api/get_notifications/?user_id=' + userId, {
                method: "GET",
                credentials: 'include'
            })
            .then(response => response.json())
            .then(data => {
                const notificationsArray = data.notifications;
                consoleLog("Notifs:", notificationsArray);

                if (notificationsArray && notificationsArray.length > 0) {
                    const filteredNotifications = notificationsArray.filter(notification =>
                        notification.type !== "SENT_FRIEND_REQ" &&
                        notification.type !== "INC_FRIEND_REQ"
                    );


                    setSentFriendRequests(
                        notificationsArray.filter(notification =>
                            notification.type === "SENT_FRIEND_REQ" &&
                            !listFriends.has(notification.secondary_user_id)
                        )
                    );


                    setReceievedFriendRequests(
                        notificationsArray
                            .filter(notification => notification.type === "INC_FRIEND_REQ")
                            .filter((notification, index, self) =>
                                // Check if this is the first occurrence of this secondary_user_id
                                index === self.findIndex(n => n.secondary_user_id === notification.secondary_user_id)
                            )
                    );
                    if (filteredNotifications.length > 0) {
                        setNotifications(filteredNotifications.map(notification => ({
                            id: notification.id,
                            message: notification.message,
                            onClick: () => {
                                consoleLog(`Notification clicked: ${notification.message}`);
                            }
                        })));
                    } else {
                        setNotifications([{ id: -1, message: 'No notifications', onClick: () => {} }]);
                    }
                } else {
                    setNotifications([{ id: -1, message: 'No notifications', onClick: () => {} }]);
                }
            })
            .catch(error => {
                consoleError('Error fetching notifications:', error);
                setNotifications([{ id: -1, message: 'No notifications', onClick: () => {} }]);
            });
        }
    }, [userId]);

    const [friends, setFriends] = useState([]);
    const [listFriends, setListFriends] = useState(new Set());
    useEffect(() => {
        if (userId) {
            // Fetch the list of friends
            fetch(BASE_URL + '/api/get_friends/?user_id=' + userId, {
                method: "GET",
                credentials: 'include'
            })
            .then(response => response.json())
            .then(data => {
                consoleLog("Friends:", data.Friends);

                // Iterate over each friend and fetch their details
             data.Friends.forEach(friend => {
                const friendId = friend.friend_id;

                // First, try to fetch the coach name
                fetch(BASE_URL + '/api/get_coachid?user_id=' + friendId, {
                    method: "GET",
                    credentials: 'include'
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch coach data'); // Ensure the error gets thrown if the response is not OK
                    }
                    return response.json();
                })
                .then(coachData => {
                    consoleLog('Coach Data:', coachData);
                    setListFriends(prevSet => new Set(prevSet).add(coachData[1])); // Assuming coachData[1] is the coach name
                })
                .catch(() => {
                    consoleLog('Coach ID fetch failed, attempting to fetch Athlete ID');

                    // If fetching the coach data fails, try to fetch the athlete data
                    fetch(BASE_URL + '/api/get_athleteid?user_id=' + friendId, {
                        method: "GET",
                        credentials: 'include'
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Failed to fetch athlete data');
                        }
                        return response.json();
                    })
                    .then(athleteData => {
                        consoleLog('Athlete Data:', athleteData);
                        setListFriends(prevSet => new Set(prevSet).add(athleteData[1])); // Assuming athleteData[1] is the athlete name
                    })
                    .catch(error => {
                        consoleError('Error fetching both coach and athlete data for friend:', error);
                    });
                });

                consoleLog("Trying to fetch coach ID or fallback to athlete ID");
            });
            })
            .catch(error => {
                consoleError('Error fetching friends:', error);
            });
        }
        consoleLog("Friends Found:", listFriends);
    }, [userId]);
    consoleLog("Names ", listFriends);





        // useEffect(() => {
        //     if (coachId > -1) {
        //         fetch(BASE_URL + '/api/get_unassigned_film_count?coachUserId=' + userId, {
        //             method: "GET",
        //             credentials: 'include'
        //         })
        //         .then(response => response.json())
        //         .then(data => {
        //             if (data > 0) {
        //                 msg = `You have ${data} unassigned film`;
        //                 if (data === 1) {
        //                     msg += '!';
        //                 } else {
        //                     msg += 's!';
        //                 }
        //                 const notificationList = [{ id: 1, message: msg, onClick: handleAssignFilm }];
        //                 setNotifications(notificationList);
        //             } else {
        //                 const notificationList = [{ id: -1, message: 'No notifications', onClick: () => {} }];
        //                 setNotifications(notificationList);
        //             }
        //         })
        //         .catch(error => {
        //             consoleLog('No unassigned videos: ', error);
        //         });
        //     }
        // }, [userId, coachId, flip_on_change]);

    const toggleDropdown = () => {
        if (!isprofileDropdownOpen) {
            notificationCloseDropdown()
        }
        setIsprofileDropdownOpen(!isprofileDropdownOpen);
    };

    const closeDropdown = () => {
        setIsprofileDropdownOpen(false);
    };

    const handleProfileClick = () => {
    closeDropdown(); // Close the dropdown before navigating
        history.push({
          pathname: isCoach ? `/coach-profile/` : `/profile/`,
          state: {
            userId: userId,
            name: name,
            address: address,
          }
        });
    };

    const notificationToggleDropdown = () => {
        if (!isNotificationDropdownOpen) {
            closeDropdown();
        }
        setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
    };

    const notificationCloseDropdown = () => {
        setIsNotificationDropdownOpen(false);
    };

    const toggleFriendDropdown = () =>{
        if(isFriendDropdownOpen){
            friendCloseDropdown();
        }
        else{
            setIsFriendDropdownOpen(true);
        }

    }
    const friendCloseDropdown = () => {
        setIsFriendDropdownOpen(false);
        setIsRequestSent(false);
    };



    const handleSendRequest = () =>{
        if (newFriend) {
           //consoleLog("Friend request Sent");
           setIsRequestSent(true);


           const notificationData = {
            message: `${name} has sent you a friend request.`,
            user_id: newFriend.value,
            redirect_url: "URL to the relevant profile or friends list page",
            type: "INC_FRIEND_REQ",
            secondary_user_id: userId
            };
           //consoleLog(notificationData);

            fetch(BASE_URL + '/api/add_notifications/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(notificationData),
            })
            .then(response => response.json())
            .then(data => {
                consoleLog("Successfully added notification for the friend request!");
            })
            .catch(error => {
                consoleError('Error adding notification: ', error);
            });

            const fullString = newFriend.label;
            const namePart = fullString.split(' (')[0];

            const ownNotificationData = {
                message: `You have sent a friend request to ${namePart}.`,
                user_id: userId,
                redirect_url: "URL to the relevant profile or friends list page",
                type: "SENT_FRIEND_REQ"
            };
            //consoleLog(ownNotificationData);

            fetch(BASE_URL + '/api/add_notifications/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ownNotificationData),
            })
            .then(response => response.json())
            .then(data => {
                consoleLog("Successfully added notification for your friend request!");
            })
            .catch(error => {
                consoleError('Error adding notification: ', error);
            });

            //add this notification to notifications:
            const newNotification = {
                user_id: userId,
                message: ownNotificationData.message,
                is_read: false,
                redirect_url: ownNotificationData.redirect_url,
                type: ownNotificationData.type,
            };
            setNotifications(notifications => [...notifications, newNotification]);
            setSentFriendRequests(sentFriendRequests => [...sentFriendRequests, newNotification]);

            setNewFriend(null);


        }

    }

    const [tempAcceptedRequests, setTempAcceptedRequests] = useState([]);
    const [tempRejectedRequests, setTempRejectedRequests] = useState([]);

    const handleAccept = (notificationId, friendId) => {
        setTempAcceptedRequests([...tempAcceptedRequests, notificationId]);

        const foundRequest = receivedFriendRequests.find(request => request.id === notificationId);

        if (foundRequest) {
            consoleLog("Found Request:", foundRequest);
            consoleLog("Secondary User ID:", foundRequest.secondary_user_id);

            handleAddFriend(foundRequest.secondary_user_id);
        } else {
            consoleLog("No matching request found for notificationId:", notificationId);
        }

        //Remove from list of notifications (remove from backend)
        deleteNotification(notificationId);
        //need to delete notification for other user: (or can implement a check that
        //if same other user ID in friend requests + friends then delete.
        //deleteNotification(notificationId);


    };

    const deleteNotification = (notificationId) => {
    fetch(BASE_URL + `/api/delete_notification/${notificationId}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        if (response.status === 204) {
            consoleLog("Notification deleted successfully.");
        } else {
            response.json().then(data => {
                consoleError('Error:', data.error);
            });
        }
    })
    .catch(error => {
        consoleError('Error deleting notification:', error);
    });
};


    const handleAddFriend = (friendId) =>{
        const frienddata = {
            friend_id: friendId,
            user_id: userId
        };
        consoleLog("add friend data:", frienddata);
        fetch(BASE_URL + '/api/add_friend/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(frienddata),
            })
            .then(response => response.json())
            .then(data => {
                consoleLog("Successfully added friend!");
            })
            .catch(error => {
                consoleError('Error adding friend: ', error);
            });
    };

    const handleReject = (notificationId) => {
        setTempRejectedRequests([...tempRejectedRequests, notificationId]);
        deleteNotification(notificationId);
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
            if (notificationDropdownRef.current && !notificationDropdownRef.current.contains(event.target)) {
                notificationCloseDropdown();
            }
            if (friendDropdownRef.current && !friendDropdownRef.current.contains(event.target)) {
                friendCloseDropdown();
            }

        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="profile-container" ref={profileDropdownRef}>
                <img
                    className="profile-icon"
                    src={require("../../static/img/---icon--profile-circle-@2x.png")}
                    alt="icon profile circle"
                    onClick={toggleDropdown}
                />
                {isprofileDropdownOpen && (
                    <div className="dropdown-menu" onClick={closeDropdown}>
                        <ul>
                            <li key='profile-info' onClick={handleProfileClick}>Profile</li>
                            <li key='logout' onClick={onLogout || handleLogout}>Log out</li>
                        </ul>
                    </div>
                )}
            </div>

            <div className="notification-container" ref={notificationDropdownRef}>
                <img
                    className="notification-icon"
                    src={require("../../static/img/notifications-circle-outline-svgrepo-com.png")}
                    alt="notification icon"
                    onClick={notificationToggleDropdown}
                />

                {notifications[0]['id'] !== -1 && (
                    <div className="notification-badge">{notifications.length}</div>
                )}

                {isNotificationDropdownOpen && (
                    <div className="notification-dropdown-menu" onClick={notificationCloseDropdown}>
                        <ul>
                            {notifications.map(notification => (
                                <li key={notification.id} onClick={notification.onClick}>
                                    {notification.message}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div className="mail-container">
                <img
                    className="mail-icon"
                    src={require("../../static/img/mail.png")}
                    alt="notification icon"
                />
            </div>
            <div className="friend-container" ref={friendDropdownRef}>
                <img
                    className="friend-icon"
                    src={require("../../static/img/addfriend.png")}
                    alt="notification icon"
                    onClick={toggleFriendDropdown}
                />
                {receivedFriendRequests.length > 0 && (
                    <div className="notification-badge">{receivedFriendRequests.length}</div>
                )}
                {isFriendDropdownOpen && (
                    <div className="friend-dropdown-menu">
                        {!requestSent ? (
                            <ul>
                                <li>
                                    <Select
                                        value={newFriend}
                                        onChange={(selectedOption) => setNewFriend(selectedOption)}
                                        placeholder="Search Friends"
                                        options={allUsers} // Assuming allTeams is the list of teams you want to display
                                        isClearable
                                        isSearchable
                                        menuPortalTarget={document.body}
                                        styles=
                                            {{
                                                menuPortal: base => ({...base, zIndex: 9999}),
                                                menu: (base) => ({
                                                    ...base,
                                                    color: 'black',
                                                })

                                            }}
                                    />

                                </li>
                                <li
                                    onClick={newFriend ? handleSendRequest : undefined}
                                    style={{
                                        cursor: newFriend ? 'pointer' : 'not-allowed',
                                        color: newFriend ? 'black' : 'grey',
                                        textAlign: 'center',
                                        opacity: newFriend ? 1 : 0.5,
                                    }}
                                >
                                    Send Request
                                </li>

                                {receivedFriendRequests.map(notification => (
                                    <li key={notification.id}>
                                        <div>{notification.message}</div>

                                        <div className="friend-request-actions">
                                            {tempAcceptedRequests.includes(notification.id) ? (
                                                <span className="accepted-text">Accepted</span>
                                            ) : tempRejectedRequests.includes(notification.id) ? (
                                                <span className="rejected-text">Rejected</span>
                                            ) : (
                                                <>
                                                    <button className="accept-button"
                                                            onClick={() => handleAccept(notification.id, notification.message)}>Accept
                                                    </button>
                                                    <button className="reject-button"
                                                            onClick={() => handleReject(notification.id)}>Reject
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </li>

                                ))}

                                {sentFriendRequests.length > 0 && (
                                    <div className="outgoing-requests-section">
                                        <li> Outgoing Requests </li>
                                    </div>
                                )}

                                {sentFriendRequests.map(notification => (
                                    <li className="outgoing-request" key={notification.id}>
                                        {notification.message}
                                    </li>
                                ))}

                                {listFriends.size > 0 && (
                                    <div className="outgoing-requests-section">
                                        <li> Friends </li>
                                    </div>
                                )}

                                {[...listFriends].map((name, index) => (
                                    <li className="outgoing-request" key={index}>
                                        You are friends with {name}
                                    </li>
                                ))}



                            </ul>
                        ) : (
                            <ul>
                                <li> Request sent!</li>
                            </ul>
                        )}
                    </div>
                )}
            </div>

        </>
    );
}

export default ProfileIcon;
