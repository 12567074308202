export function consoleLog(message, data=null) {
    if (process.env.NODE_ENV !== 'production') {
        if (data) {
            console.log(message, data);
        } else {
            console.log(message);
        }
    }
};

export function consoleError(message, data=null) {
    if (process.env.NODE_ENV !== 'production') {
        if (data) {
            console.error(message, data);
        } else {
            console.error(message);
        }
    }
};